import { initFlowbite } from "flowbite";
import Alpine from "alpinejs";
import "htmx.org";

// Inicializando flowbite.
initFlowbite();

// Inicializando Alpine.js.
window.Alpine = Alpine;

// Inicializando HTMX
window.htmx = require("htmx.org");

Alpine.start();
